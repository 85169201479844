import React, {useRef} from "react";
import * as styles from "./contact-form.module.scss";
import classNames from "classnames";
import { useForm } from "react-hook-form"
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ErrorMessage } from '@hookform/error-message';
import {Link} from "gatsby";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const FORM_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Zadejte prosím vaše jméno."),
  surname: Yup.string().required("Zadejte prosím vaše příjmení."),
  email: Yup.string()
    .required("Zadejte prosím váš e-mail.")
    .email("Zadejte prosím platnou e-mailovou adresu."),
  phone: Yup.string()
    .matches(phoneRegExp,"Zadejte prosím své telefonní číslo v platném formátu.")
    .required("Zadejte prosím vaše telefonní číslo."),
  message: Yup.string().required("Zadejte prosím zprávu."),
  privacyAgreement: Yup.boolean()
    .required("Nutno souhlasit s podmínkami soukromí")
    .oneOf([true], "Nutno souhlasit s podmínkami soukromí"),
});

const ContactForm = ({flatId, contactInfo, title}) => {
  const formButtonRef = useRef(null);

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FORM_SCHEMA),
  })

  const onSubmit = data => {
    let readyData;

    if (data.flatSpecific) {
      readyData = {
        form: {
          fullName: data?.name + " " + data?.surname,
          email: data?.email,
          phone: data?.phone,
          message: data?.message,
          flatId: flatId
        }
      }
    } else {
      readyData = {
        form: {
          fullName: data?.name + " " + data?.surname,
          email: data?.email,
          phone: data?.phone,
          message: data?.message
        }
      }
    }

    const config = {
      headers: { "Content-Type": "application/json" }
    }

    axios.post(process.env.GATSBY_API_FORM_URL, readyData, config)
      .then(function (response) {
        formButtonRef.current.innerHTML = "Úspěšně odesláno";
        formButtonRef.current.disabled = true;
      })
      .catch(function (error) {
        formButtonRef.current.innerHTML = "Někde se stala chyba";
      });
  }

  return (
    <section className={classNames({
      [styles.container]: true,
      [styles.noMargin]: flatId != null
    })} data-navigation-id="kontakt">
      <div className={styles.content}>
        <h2>{title ? title : "Napište nám"}</h2>
        <div className={styles.wrapper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputWrapper}>
              <label htmlFor="name">Jméno</label>
              <input type="text" name="name" id="name" {...registerField("name")} />
              <ErrorMessage name={"name"} errors={errors}
                            render={({ message }) => <small>{message}</small>}
              />
            </div>
            <div className={styles.inputWrapper}>
              <label htmlFor="surname">Příjmení</label>
              <input type="text" name="surname" id="surname" {...registerField("surname")} />
              <ErrorMessage name={"surname"} errors={errors}
                            render={({ message }) => <small>{message}</small>}
              />
            </div>
            <div className={styles.inputWrapper}>
              <label htmlFor="email">E-mail</label>
              <input type="text" name="email" id="email" {...registerField("email")} />
              <ErrorMessage name={"email"} errors={errors}
                            render={({ message }) => <small>{message}</small>}
              />
            </div>
            <div className={styles.inputWrapper}>
              <label htmlFor="phone">Telefon</label>
              <input type="text" name="phone" id="phone" {...registerField("phone")} />
              <ErrorMessage name={"phone"} errors={errors}
                            render={({ message }) => <small>{message}</small>}
              />
            </div>
            <div className={styles.inputWrapperWide}>
              <label htmlFor="message">Zpráva</label>
              <textarea name="message" id="message" rows={8} {...registerField("message")} />
              <ErrorMessage name={"message"} errors={errors}
                            render={({ message }) => <small>{message}</small>}
              />
            </div>
            {flatId != null && (
              <div className={styles.inputWrapperWide} style={{textAlign: "right"}}>
                <p>Mám zájem o právě zobrazovaný byt</p>
                <input type={"checkbox"}
                       name="flatSpecific"
                       id="flatSpecific"
                       defaultChecked={true}
                       {...registerField("flatSpecific")}
                />
              </div>
            )}
            <div className={styles.inputWrapperWide} style={{textAlign: "right"}}>
              <p>Souhlasím s <Link to={"/privacy-policy"} style={{color: "white"}}>podmínkami soukromí</Link></p>
              <input type={"checkbox"}
                     name="privacyAgreement"
                     id="privacyAgreement"
                     {...registerField("privacyAgreement")}
              />
              <ErrorMessage name={"privacyAgreement"} errors={errors}
                            render={({ message }) => <small style={{marginLeft: "auto"}}>{message}</small>}
              />
            </div>
            <div className={styles.buttonWrapper}>
              <button ref={formButtonRef}>Odeslat</button>
            </div>
          </form>
          <div className={styles.contactInfo}>
            <h4>Kontakt</h4>
            {contactInfo.email != null && <a href="mailto:">{contactInfo.email}</a>}
            {contactInfo.phone != null && <a href="tel:">{contactInfo.phone}</a>}
            {contactInfo.name != null && <p>{contactInfo.name}</p>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm;
